<template>
  <div id="app">
    <cssreset />
    <router-view/>
  </div>
</template>

<style lang="scss">
html, body{
  margin: 0;
  padding: 0;
}
.innerMaxWidth{
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
}
#app{
  @media screen and (max-width: 700px) {
    overflow-x: hidden;
  }
}
@font-face {
  font-family: 'Marcellus';
  src: url('~@/assets/Marcellus-Regular.woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
*{
  font-family: 'Marcellus' !important;
  box-sizing: border-box;
  color: rgba(0,0,0,0.8);
}
html, body{
  font-size: 10px;
  @media screen and (min-width: 700px) {
    font-size: 16px;
  }
}
.maxWidth{
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 20px;
  @media screen and (max-width: 1800px) {
    padding: 20px;
  }
}
@media screen and (min-width: 700px) {
  .posCenter {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
<script>
import Cssreset from "@/components/cssreset";
export default {
  components: {Cssreset}
}
</script>
