<template>
  <div class="footer">
    <div class="">
      <footer>
        <div class="socials">
          <a href="https://www.instagram.com/wongebergmann/" target="_blank">
            <img src="../assets/instagram.svg" />
          </a>
          <a href="https://www.linkedin.com/in/wonge-bergmann-7b491511/" target="_blank">
            <img src="../assets/linkedin.svg" />
          </a>
        </div>
        <hr />
        © WONGE BERGMANN
        <div class="router_links" @click="openLegal">
          <span>IMPRESSUM</span>
          <span>DATENSCHUTZ</span>
          <span>AGB</span>
        </div>
        <div class="legal__hide" v-if="showLegal">
          <div class="legal legal__no-column">
            <b ref="imprint">Impressum</b>
            <p >
              Wonge Bergmann
            </p>
            <p >
              Schadowstraße 2</p>
            <p >
              60596 Frankfurt am Main,
              Deutschland</p>
            <p >
              <u><a href="mailto:wonge@wongebergmann.com">wonge@wongebergmann.com</a></u></p>
            <p>
              +49 (0) 171 620 66 33
            </p>
            <p>
              Hinweise gemäß $ 5 Tele Mediengesetz (TMG)
            </p>
            <p>
              Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz:<br />
              DE 212505393
            </p>
          </div>
          <div class="legal legal__line">
            <p>
              <b>Gestaltung:</b>
            </p>
            <p>
              Rike Sattler / Hamburg / <a href="https://www.rikesattler.com" target="_blank">www.rikesattler.com</a>
            </p>
            <p>
              <b>Programmierung:</b>
            </p>
            <p style="break-after: column;">
              Peter Wingen / Heiligenhaus / <a href="https://www.wingen.de" target="_blank">www.wingen.de</a>
            </p>
            <p>
              Danke an Martin U.K. Lengemann/ Fotograf Porträt Wonge Bergmann/ Berlin/ <a href="http://www.lengemann.co.uk" target="_blank">www.lengemann.co.uk</a>
            </p>
          </div>
          <div class="legal legal__line">
            <p>
              <b>Fotohinweise :</b>
            </p>
            <p>
              <b>Foodfotos:</b>
            </p>
            <ul>
              <li>Nr.1: Gericht Grüner Spargel mit Misocreme, Sojaschaum und Umeboshi Pflaumen von/für Nils Henkel</li>
              <li>Nr.2: Gericht Gin Tonic Limone Nitro nach Heston Blumenthal von Juan Amador für Buchprojekt „Juan Amador Tapas - Das Kochbuch“ TreTorri Verlag</li>
              <li>Nr.3: Dessert Zwetschge mit rote Beete, Heumilch und Kardamom von Nils Henkel</li>
              <li>Nr.4: Gericht Rote-Bete-Bouillon fest-flüssig mit Wasabi von Juan Amador für Buchprojekt „Juan Amador Tapas - Das Kochbuch“ im TreTorri Verlag</li>
              <li>Nr.5: Sechuam Blüte/ Parakresse für Juan Amador</li>
              <li>Nr.6: Gericht Klettenwurzel mit Schwarzwaldmiso, Ponzu und Shytake-Gyoza von/für Nils Henkel</li>
              <li>Nr.7: Dessert Schwarze Kirsche, Mais, Porceklana Schokolade und Fichtennadel von / für Nils Henkel</li>
              <li>Nr.8: Gaspacho von/für Juan Amador</li>
              <li>Nr.9: Gericht Pilze aus dem Nordschwarzwald in Vin June mit sanft gegartem Eigelb vom Landhuhn, Krause Glucke, Blutreizker, Pfifferlingen und rotem Gallerttrichter von Torsten Michel/ Restaurant Schwarzwaldstube</li>
              <li>Nr.10: erster Gang " Japanisches Meer" mit Strandkräuter, Meeresfrüchte, Kampachii (Gelbflossenmakrele) (in Ikejime Zubereitung), Seeigel Tigermilk und geeister roter  Shiso des Menüs Paris-Tokio von Christian Bau/ Restaurant  „Victor´s Fine Dining“</li>
              <li>Nr.11: Detailansicht erster Gang " Japanisches Meer" mit Strandkräuter, Meeresfrüchte, Kampachii (Gelbflossenmakrele) (in Ikejime Zubereitung), Seeigel Tigermilk und geeister roter  Shiso des Menüs Paris-Tokio von Christian Bau „Victor´s Fine Dining"</li>
              <li>Nr.12: Zwischengang roter Gamberoni, Blumenkohl, Kombi und Koji des Menüs Paris-Tokio von Christian Bau /Restaurant  „Victor´s Fine Dining"</li>
            </ul>

            <p style="break-before: column;">
              <b>Rubrik Porträt:</b>
            </p>
            <ul>
              <li>Nr.1: Hannelore Elsner (* 1942 † 2019) Schauspielerin, Synchronsprecherin  und Autorin</li>
              <li>Nr.2: Heiner Goebbels/ Musiker, Komponist, Regisseur</li>
              <li>Nr.3: Andreas Slomiski, Künstler</li>
              <li>Nr.4: Lil Roch, Breakdancer</li>
              <li>Nr.5: gibt es nicht! Ein Versehen meinerseits!</li>
              <li>Nr.6: Thomas Köhl, Gründer und Geschäftsführer von Köhl Sitzmöbel</li>
              <li>Nr.7: Torsten Michel / 3 Sternekoch im Restaurant Schwarzwaldstube</li>
              <li>Nr.8: Friedrich Kueppersbusch/ Journalist, Moderator, Autor und Fernsehproduzent</li>
              <li>Nr.9: Sebastian Köhnlechner / Managing Partner Asset - Agentur für Finanzkommunikation</li>
              <li>Nr.10: Maria Groß/ Sterneköchin, Matthias Steube/ Lebenspartner und Pudel Friedrich Groß auf der Terrasse des gemeinsam geführten Restaurants Bachstelze</li>
              <li>Nr.11: Gruppenporträt des Ensemble Modern</li>
              <li>Nr.12: Katja Hilbig/ Head of Exhibition  Städel Museum & Liebieg Haus, Frankfurt am Main</li>
              <li>Nr.13: Helga und Hartmut Rausch, Hausmeisterehepaar an der Städelschule vor der Bar „Golden Rausch"</li>
              <li>Nr.14: Ardi Goldman/ Immobilienunternehmer und Clubbesitzer in seinem Club „Fortuna Irgendwo“</li>
              <li>Nr.15: Marina Abramović, Performance Künstlerin</li>
              <li>Nr.16: Philipp Pflug/ Galerist</li>
              <li>Nr.17: Rüdiger Volhard, Jurist, Kunst- und Kulturmäzen</li>
              <li>Nr.18: Nils Landgren/ schwedischer Posaunist, Komponist, Arrangeur und Sänger</li>
              <li>Nr.19: Allan H. Meltzer, US - amerikanischer Wirtschaftswissenschaftler</li>
              <li>Nr.20: Eintracht Frankfurt Maskottchen Adler Attila</li>
            </ul>

            <p>
              <b>
                Rubrik „Wonge“
              </b>
            </p>
            <ul>
              <li>Nr.7: bis auf die Knochen vollständig aufgegessener Hauptgang "Eisbein" von Tim Raue als Koch des Jahres im Rahmen des Gala Diners zur Preisverleihung „Lieblinge des Jahres 2015" der Frankfurter Allgemeine Sonntagszeitung ( F.A.S. )</li>
            </ul>


          </div>
          <div class="legal legal__line">
            <b>Allgemeine Liefer- und
              Geschäftsbedingungen (AGB)&nbsp;</b>
            <ol>
              <li><p >
                Allgemeines&nbsp;</p></li>
            </ol>
            <p >
              1. Die nachfolgenden allgemeinen
                Liefer- und Geschäftsbedingungen (im&nbsp;</p>
            <p >
              folgenden AGB genannt) gelten für
                alle von Wonge Bergmann (im nachfolgenden Wonge Bergmann oder
                Fotograf genannt) durchgeführten Aufträge, Angebote, Lieferungen
                und Leistungen.&nbsp;</p>
            <p >
              2. Sie gelten als vereinbart mit
                Entgegennahme der Lieferung oder Leistung&nbsp;bzw. des Angebots von
                Wonge Bergmann durch den Kunden,&nbsp;spätestens jedoch mit der
                Annahme des Bildmaterials zur Veröffentlichung.&nbsp;</p>
            <p ><a name="_GoBack"></a>
              3. Wenn der Kunde den AGB
                widersprechen will, ist dieses schriftlich binnen&nbsp;drei Werktagen
                zu erklären. Abweichenden Geschäftsbedingungen des&nbsp;Kunden wird
                hiermit widersprochen. Abweichende Geschäftsbedingungen&nbsp;des
                Kunden erlangen keine Gültigkeit, es sei denn, dass Wonge Bergmann
                diese schriftlich anerkennt oder diese so vereinbart sind.</p>
            <p >
              4. Die AGB gelten im Rahmen einer
                laufenden Geschäftsbeziehung auch&nbsp;ohne ausdrückliche
                Einbeziehung auch für alle zukünftigen Aufträge,&nbsp;Angebote,
                Lieferungen und Leistungen von Wonge Bergmann, bei Abweichungen nur
                entsprechend vereinbarter Regelungen.&nbsp;</p>
            <ol>
              <li><p >
                Überlassenes Bildmaterial&nbsp;</p></li>
            </ol>
            <p >
              1. Die AGB gelten für jegliches
                dem Kunden überlassenes Bildmaterial,&nbsp;gleich in welcher
                Schaffensstufe oder in welcher technischen Form es&nbsp;vorliegt. Sie
                gelten insbesondere auch für elektronisches oder
                digital&nbsp;übermitteltes Bildmaterial.&nbsp;</p>
            <p >
              2. Der Kunde erkennt an, dass es
                sich bei dem von Wonge Bergmann&nbsp;gelieferten Bildmaterial um
                Lichtbildwerke im Sinne von § 2 Abs.1 Ziff. 5&nbsp;UrhRG handelt.&nbsp;</p>
            <p >
              3. Vom Kunden in Auftrag gegebene
                Gestaltungsvorschläge oder&nbsp;Konzeptionen sind eigenständige
                Leistungen, die zu vergüten sind.&nbsp;</p>
            <p >
              4. Das überlassene Bildmaterial
                bleibt Eigentum des Wonge Bergmann,&nbsp;und zwar auch in dem Fall,
                dass Schadensersatz hierfür geleistet wird.</p>
            <p >
              5. Reklamationen, die den Inhalt
                der gelieferten Sendung oder Inhalt,&nbsp;Qualität oder Zustand des
                Bildmaterials betreffen, sind innerhalb von 48&nbsp;Stunden nach
                Empfang mitzuteilen. Anderenfalls gilt das Bildmaterial
                als&nbsp;ordnungsgemäß, vertragsgemäß und wie verzeichnet
                zugegangen.&nbsp;</p>
            <p >
              III. Nutzungsrechte&nbsp;</p>
            <p >
              1. Der Kunde erwirbt nur ein
                einfaches Nutzungsrecht zur&nbsp;einmaligen Verwendung.&nbsp;Es sei
                denn, es sind gesonderte Vereinbarungen zum Nutzungsrecht getroffen.</p>
            <p >
              2. Ausschließliche
                Nutzungsrechte, medienbezogene oder räumliche&nbsp;Exklusivrechte
                oder Sperrfristen müssen gesondert vereinbart werden.&nbsp;</p>
            <p >
              3. Mit der Lieferung wird
                lediglich das Nutzungsrecht für die einmalige&nbsp;Nutzung des
                Bildmaterials zu dem vom Kunden angegebenen Zweck und in&nbsp;der
                Publikation und in dem Medium oder Datenträger, welche/-s/-n
                der&nbsp;Kunde angegeben hat oder welche/-s/-r sich aus den Umständen
                der&nbsp;Auftragserteilung ergibt, übertragen . Im Zweifelsfall ist
                maßgeblich das&nbsp;Objekt (Zeitung, Zeitschrift usw.), für das das
                Bildmaterial ausweislich des&nbsp;Lieferscheins, der
                Kundenregistrierung im Archivsystem oder der Versandadresse zur
                Verfügung gestellt worden ist.&nbsp;</p>
            <p >
              4. Jede über Ziffer 3.
                hinausgehende Nutzung, Verwertung,&nbsp;Vervielfältigung,
                Verbreitung oder Veröffentlichung ist honorarpflichtig und&nbsp;bedarf
                der vorherigen ausdrücklichen Zustimmung.&nbsp;Das gilt insbesondere
                für:&nbsp;</p>
            <p >
              – eine Zweitverwertung oder
              Zweitveröffentlichung, insbesondere in&nbsp;Sammelbänden,
              produktbegleitenden Prospekten, bei Werbemaßnahmen&nbsp;oder bei
              sonstigen Nachdrucken,&nbsp;jegliche Bearbeitung, Änderung oder
              Umgestaltung des Bildmaterials&nbsp;</p>
            <p >
              – die Digitalisierung,
              Speicherung oder Duplizierung des Bildmaterials auf&nbsp;Datenträgern
              aller Art (z.B. magnetische, optische, magnetooptische
              oder&nbsp;elektronische Trägermedien wie CD-ROM, CDi, Disketten,
              Festplatten,&nbsp;Arbeitsspeicher, Mikrofilm etc.), soweit dieses
              nicht nur der technischen&nbsp;Verarbeitung des Bildmaterials gem.
              Ziff. III 3. AGB dient,</p>
            <p >
              – jegliche Vervielfältigung
              oder Nutzung der Bilddaten auf CD-ROM, CDi,&nbsp;Disketten oder
              ähnlichen Datenträgern,&nbsp;</p>
            <p >
              – jegliche Aufnahme oder
              Wiedergabe der Bilddaten im Internet/WWW oder in&nbsp;Online-
              Datenbanken oder in anderen elektronischen Archiven (auch soweit&nbsp;es
              sich um interne elektronische Archive des Kunden handelt),&nbsp;</p>
            <p >
              – die Weitergabe des
              digitalisierten Bildmaterials im Wege der&nbsp;Datenfernübertragung
              oder auf Datenträgern, die zur öffentlichen&nbsp;Wiedergabe auf
              Bildschirmen oder zur Herstellung von Hardcopies geeignet&nbsp;sind.&nbsp;</p>
            <p >
              5. Veränderungen des
                Bildmaterials durch Foto-Composing,&nbsp;Montage/Collage oder durch
                elektronische Hilfsmittel zur Erstellung eines&nbsp;neuen
                urheberrechtlich geschützten Werkes sind nur nach
                vorheriger&nbsp;schriftlicher Zustimmung von Wonge Bergmann und <b>nur
              bei&nbsp;Kennzeichnung mit [M] bei Nutzung im journalistischem
              Kontext gestattet</b>.
              Auch darf das Bildmaterial nicht&nbsp;abgezeichnet, abgefilmt,
              nachgestellt fotografiert oder anderweitig als Motiv&nbsp;benutzt
              werden.&nbsp;</p>
            <p >
              6. Der Kunde ist nicht
                berechtigt, die ihm eingeräumten Nutzungsrechte&nbsp;ganz oder
                teilweise auf Dritte, auch nicht auf andere Konzern-
                oder&nbsp;Tochterunternehmen, zu übertragen.&nbsp;</p>
            <p >
              7. Jegliche Nutzung, Wiedergabe
                oder Weitergabe des Bildmaterials ist nur&nbsp;gestattet unter der
                Voraussetzung der Anbringung des vorgegebenen Urhebervermerks („Wonge
                Bergmann / www.wongebergmann.com“, mindestens jedoch „Wonge
                Bergmann“ oder entsprechend gesondert vereinbarter
                Urhebervermerke). Abweichende Regelungen sind gesondert schriftlich
                zu vereinbaren.</p>
            <p >
              8. Der Kunde verpflichtet sich,
                bei der Verwendung der Aufnahmen&nbsp;Allgemeine
                Persönlichkeitsrechte und das Recht am eigenen Bild
                abgebildeter&nbsp;Personen nicht zu verletzen. Der Kunde ist ggf. zur
                Einholung erforderlicher&nbsp;Einwilligungen verpflichtet und haftet
                für Schadensersatzansprüche, die in&nbsp;diesem Zusammenhang
                entstehen. Es gilt als vereinbart, dass Wonge Bergmann grundsätzlich
                nur Bildmaterial ohne Einverständniserklärungen/Model Release der
                abgebildeten Personen zur&nbsp;Verfügung stellt.&nbsp;</p>
            <p >
              IV. Honorare&nbsp;</p>
            <p >
              1. Es gilt das vereinbarte
                Honorar. Ist kein Honorar vereinbart worden,&nbsp;bestimmt es sich
                nach der jeweils aktuellen Bildhonorarübersicht
                der&nbsp;Mittelstandsgemeinschaft Foto-Marketing (MFM). Es gilt ein
                Mindesthonorar von 50,- €. Das Honorar versteht sich zuzüglich der
                jeweils gültigen Mehrwertsteuer.&nbsp;</p>
            <p >
              2. Das Honorar gilt nur für die
                einmalige Nutzung des Bildmaterials zu dem&nbsp;vereinbarten Zweck
                gemäß Ziff.III 3. oder 2. AGB. Soll das Honorar auch&nbsp;für eine
                weitergehende Nutzung bestimmt sein, ist dieses schriftlich
                zu&nbsp;vereinbaren.&nbsp;</p>
            <p >
              3. Durch den Auftrag anfallende
                Kosten und Auslagen (z.B. Material-und&nbsp;Laborkosten,
                Modellhonorare, Kosten für erforderliche Requisiten,&nbsp;Reisekosten,
                erforderliche Spesen etc.) sind nicht im Honorar enthalten und&nbsp;gehen
                zu Lasten des Kunden.&nbsp;Abweichungen von dieser Kostenregelung
                sind schriftlich zu vereinbaren</p>
            <p >
              4. Das Honorar gemäß IV. 1. AGB
                ist auch dann in voller Höhe zu zahlen,&nbsp;wenn das in Auftrag
                gegebene und gelieferte Bildmaterial nicht veröffentlicht&nbsp;wird.
                Bei Verwendung der Aufnahmen als Arbeitsvorlage für Layout-
                und&nbsp;Präsentationszwecke fällt vorbehaltlich einer abweichenden
                Vereinbarung&nbsp;ein Honorar von mindestens 100,00 Euro pro Aufnahme
                an.&nbsp;</p>
            <p >
              5. Das Honorar ist spätestens
                binnen 2 Wochen nach Rechnungsstellung zu&nbsp;zahlen, soweit in der
                Rechnung keine kürzere Zahlungsfrist angegeben ist.&nbsp;Nach einer
                Mahnung kommt der Kunde in Verzug. Nach Eintritt des Verzugs&nbsp;ist
                das Honorar mit 10% p.a. zu verzinsen. Eine Aufrechnung oder
                die&nbsp;Ausübung des Zurückbehaltungsrechts ist nur gegenüber
                unbestrittenen&nbsp;oder rechtskräftig festgestellten Forderungen
                des Kunden zulässig.&nbsp;</p>
            <p >
              V. Rückgabe des Bildmaterials&nbsp;</p>
            <p >
              1. Das Bildmaterial ist, <b>es
              sei denn es handelt sich um digitale Bilddaten</b>,
              in der gelieferten Form unverzüglich nach der&nbsp; Veröffentlichung
              oder der vereinbarten Nutzung, spaetestens jedoch 1 Monat&nbsp; nach
              dem Lieferdatum, unaufgefordert zurückzusenden. Beizufügen sind&nbsp;
              zwei Belegexemplare. Eine Verlängerung der 1-Monatsfrist bedarf der
              schriftlichen Genehmigung des Fotografen.&nbsp;</p>
            <p >
              2. Überlässt der Fotograf auf
                Anforderung des Kunden oder mit&nbsp;dessen Einverständnis
                Bildmaterial lediglich zum Zwecke der Prüfung, ob&nbsp;eine Nutzung
                oder Veröffentlichung in Betracht kommt, hat der Kunde
                das&nbsp;Bildmaterial spätestens innerhalb eines Monats nach
                Erhalt&nbsp;zurückzugeben, sofern auf dem Lieferschein keine andere
                Frist vermerkt ist.&nbsp;Eine Verlängerung dieser Frist ist nur
                wirksam, wenn sie vom&nbsp;Fotografen schriftlich bestätigt worden
                ist.&nbsp;</p>
            <p >
              3. Die Rücksendung des
                Bildmaterials erfolgt durch den Kunden auf dessen&nbsp;Kosten in
                branchenüblicher Verpackung. Der Kunde trägt das Risiko des
                Verlusts oder der Beschädigung während des Transports bis zum
                Eingang&nbsp;beim Fotografen.&nbsp;</p>
            <p >
              VI. Vertragsstrafe, Blockierung,
                Schadensersatz&nbsp;</p>
            <p >
              1. Bei jeglicher unberechtigten
                (ohne Zustimmung des Fotografen&nbsp;erfolgten) Nutzung, Verwendung,
                Wiedergabe oder Weitergabe des&nbsp;Bildmaterials ist für jeden
                Einzelfall eine Vertragsstrafe in Höhe des&nbsp;fünffachen
                Nutzungshonorars zu zahlen, vorbehaltlich
                weitergehender&nbsp;Schadensersatzansprüche.&nbsp;</p>
            <p >
              2. Bei unterlassenem,
                unvollständigem, falsch plaziertem oder nicht&nbsp;zuordnungsfähigem
                Urhebervermerk ist ein Aufschlag in Höhe von 100%&nbsp;des
                Nutzungshonorars zu zahlen.&nbsp;</p>
            <p >
              3. Bei nicht rechtzeitiger
                Rückgabe des Bildmaterials (Blockierung) ist für&nbsp;die Zeit nach
                Ablauf der in Ziff.V.1.oder 2. gesetzten Fristen eine&nbsp;Vertragsstrafe
                zu zahlen in Höhe von&nbsp;</p>
            <p >
              – Euro 1,00 pro Tag und Bild
              für s/w- oder Color-Abzüge oder Dia-&nbsp;Duplikate&nbsp;</p>
            <p >
              – Euro 2,00 pro Tag und Bild
              für Dias, Negative oder andere Unikate.&nbsp;</p>
            <p >
              4. Für beschädigtes, zerstörtes
                oder abhandengekommenes Bildmaterial&nbsp;</p>
            <p >
              ist Schadensersatz zu leisten,
                ohne dass der Fotograf die Höhe&nbsp;</p>
            <p >
              des Schadens nachzuweisen hat in
                Höhe von&nbsp;</p>
            <p >
              – Euro 40,00 pro s/w- oder
              Colorabzug oder KB-Dia-Duplikat&nbsp;</p>
            <p >
              – Euro 125,00 pro Mittel- oder
              Großformat-Dia-Duplikat&nbsp;</p>
            <p >
              – Euro 750,00 pro Dia-Original,
              Negativ oder anderem Unikat&nbsp;</p>
            <p >
              – Euro 1.500,00 pro nicht
              wiederholbarem Dia, Negativ oder anderem Unikat.&nbsp;</p>
            <p >
              Bei Beschädigungen sind die
                Sätze entsprechend dem Grad der&nbsp;Beschädigung und dem Umfang
                der weiteren Nutzungsmöglichkeit herabzusetzen. Grundsätzlich
                bleibt beiden Vertragsparteien der Nachweis&nbsp;vorbehalten, dass
                ein höherer bzw. geringerer Schaden eingetreten ist.&nbsp;</p>
            <p >
              5. Bei fehlendem Belegexemplar
                oder bei Abrechnung ohne Belegexemplar&nbsp;oder bei Abrechnung ohne
                Angabe, welches Bild an welcher Stelle in welcher&nbsp;Publikation
                verwendet worden ist, ist eine Vertragsstrafe in Höhe von 50%&nbsp;des
                Nutzungshonorars zu zahlen.&nbsp;</p>
            <p >
              6. Durch diese Zahlungen gemäß
                Ziffer VI. werden keinerlei&nbsp;Nutzungsrechte begründet.&nbsp;</p>
            <p >
              VII. Rechtliche Bestimmungen&nbsp;</p>
            <p >
              1. Es gilt das Recht der
                Bundesrepublik Deutschland als vereinbart, und zwar&nbsp;auch bei
                Lieferungen ins Ausland.&nbsp;</p>
            <p >
              2. Nebenabreden zum Vertrag oder
                zu diesen AGB bedürfen zu ihrer&nbsp;Wirksamkeit der Schriftform.&nbsp;</p>
            <p >
              3. Die etwaige Nichtigkeit bzw.
                Unwirksamkeit einer oder mehrerer&nbsp;Bestimmungen dieser AGB
                berührt nicht die Wirksamkeit der übrigen&nbsp;Bestimmungen. Die
                Parteien verpflichten sich, die ungültige Bestimmung&nbsp;durch eine
                sinnentsprechende wirksame Bestimmung zu ersetzen, die
                der&nbsp;angestrebten Regelung wirtschaftlich und juristisch am
                nächsten kommt.&nbsp;</p>
            <p >
              4. Erfüllungsort und
                Gerichtsstand ist der Wohnort des Fotografen.&nbsp;</p>
            <p >
              <span lang="en-US">Frankfurt am
  Main, März 2022</span></p>

          </div>
          <div class="legal legal__line">
            <b>Datenschutzerklärung</b>
            <p >
              Mit dieser Datenschutzerklärung möchte ich Sie darüber informieren, wie ich personenbezogene Daten verarbeite. Ich bin mir bewusst, welche Bedeutung die Verarbeitung personenbezogener Daten für den betroffenen Nutzer hat und beachte dementsprechend alle einschlägigen gesetzlichen Vorgaben. Dabei ist der Schutz Ihrer Privatsphäre für mich von höchster Bedeutung. Deshalb ist das Einhalten der gesetzlichen Bestimmungen zum Datenschutz für mich selbstverständlich. Sollten Sie darüber hinaus Fragen zum Datenschutz in Bezug auf die Nutzung meines Internetauftritts haben, wenden Sie sich bitte direkt an mich</p>
            <p >
              <b>1.Verantwortlicher</b></p>
            <p >
                Wonge Bergmann
            </p>
            <p >
              Schadowstraße 2</p>
            <p >
              60596 Frankfurt am Main,
                Deutschland</p>
            <p >
              <u><a href="mailto:wonge@wongebergmann.com">wonge@wongebergmann.com</a></u></p>
            <p >
              +491716206633</p>
            <p >
              <br/>
              <br/>

            </p>

            <p >
              <b>2. Ihre Rechte</b></p>
            <p >
              Sie haben – teilweise unter bestimmten Voraussetzungen – jederzeit das Recht, auf
            </p>
            <ul>
              <li>Auskunft über die Verarbeitung Ihrer Daten zu verlangen,</li>
              <li>Ihre Daten zu berichtigen,</li>
              <li>Ihre Daten löschen oder sperren zu lassen,</li>
              <li>die Verarbeitung einschränken zu lassen,</li>
              <li>Ihre Daten in einem übertragbaren Format zu erhalten und einem Dritten zu übermitteln,</li>
              <li>Ihre Einwilligung zur Verarbeitung Ihrer Daten für die Zukunft zu widerrufen: Die Rechtmäßigkeit der Verarbeitung Ihrer personenbezogenen Daten bis zu einem Widerruf wird durch den Widerruf nicht berührt. Ebenso unberührt bleibt eine weitere Verarbeitung dieser Daten aufgrund einer anderen Rechtsgrundlage, etwa zur Erfüllung rechtlicher Verpflichtungen.
              </li>

            </ul>
            <p>
              Hierzu wenden Sie sich bitte an die oben unter Ziffer 1. angegebenen Kontaktdaten.
            </p>
            <p>
              Darüber hinaus haben Sie das Recht auf Beschwerde bei einer Aufsichtsbehörde gemäß Artikel 77 DS-GVO. Die für mich zuständige Aufsichtsbehörde ist:
            </p>
            <p>
              <u>Recht auf Widerspruch</u><br />
              Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 e) DSGVO (Datenverarbeitung im öffentlichen Interesse) oder Art. 6 Abs. 1 f) DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen. Legen Sie Widerspruch ein, werden ich Ihre personenbezogenen Daten nur weiter verarbeiten, soweit ich dafür zwingende berechtigte Gründe nachweisen kann, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder soweit die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.
            </p>
            <p>
              <b>Der Hessische Beauftragte für Datenschutz und Informationsfreiheit</b><br />
              Postfach 3163<br />
              65021 Wiesbaden<br />
              Telefon: +49 611 1408 – 0<br />
              Telefax: +49 611 1408 – 611
            </p>
            <p >
              <b>3. Löschung/ Speicherdauer</b></p>
            <p>Ihre Daten werden von mir gespeichert,</p>
            <ul>
              <li>wenn Sie in die Verarbeitung eingewilligt haben höchstens solange, bis Sie Ihre Einwilligung widerrufen,</li>
              <li>wenn ich die Daten zur Durchführung eines Vertrags oder zur Erfüllung gesetzlicher Pflichten benötige höchstens solange, wie das Vertragsverhältnis mit Ihnen besteht oder gesetzliche Aufbewahrungsfristen laufen,</li>
            </ul>
            <p>
              Im Übrigen werden Ihre personenbezogenen Daten gelöscht, sobald sie für die genannten Zwecke nicht mehr erforderlich sind. Dabei kann es vorkommen, dass personenbezogene Daten für die Zeit aufbewahrt werden, in der Ansprüche gegen mich geltend gemacht werden können (gesetzliche Verjährungsfrist von drei bis zu dreißig Jahren). Zudem werden die  personenbezogenen Daten gespeichert, soweit und solange ich dazu gesetzlich verpflichtet bin. Entsprechende Nachweis- und Aufbewahrungspflichten ergeben sich, unter anderem aus dem Handelsgesetzbuch, der Abgabenordnung und dem Geldwäschegesetz. Die Speicherfristen betragen danach bis zu zehn Jahre.
            </p>
            <p >
              <b>4. SSL- bzw. TLS-Verschlüsselung</b></p>
            <p>Meine Internetseite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an mich als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Sie erkennen eine verschlüsselte Verbindung daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
            <p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an mich  übermitteln, nicht von Dritten mitgelesen werden.</p>
            <p >
              <b>5. Umfang, Zweck und Rechtsgrundlage der Verarbeitung personenbezogener Daten</b></p>
            <p >
              <u>5.1 Grundsätzliche Angaben zur Datenverarbeitung</u></p>
            <p>
              Ich behandele Ihre personenbezogenen Daten vertraulich und entsprechend der einschlägigen Datenschutzbestimmungen sowie unter Berücksichtigung der Gebote der Datensparsamkeit- und Datenvermeidung.
            </p>
            <p>
              Ich treffe organisatorische, vertragliche und technische Sicherheitsmaßnahmen entsprechend dem Stand der Technik, um sicher zu stellen, dass die Vorschriften der einschlägigen Datenschutzbestimmungen eingehalten werden und um damit die durch mich verarbeiteten Daten gegen zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung oder gegen den Zugriff unberechtigter Personen zu schützen.
            </p>
            <p >
              <u>
                5.2 Datenerfassung auf meiner Website
              </u></p>
            <p>
              Beim Besuch meiner Webseite werden die IP-Adressen der Besucher erfasst und in Logfiles gespeichert. Die IP-Adressen der Besucher meiner Webseite werden von meinem Webdienstleister Strato AG zur Erkennung und Abwehr von Angriffen maximal sieben Tage gespeichert.
            </p>
            <p>
              Weitere Informationen finden Sie unter:
            </p>
            <p>
              Weitere Informationen finden Sie unter:
              <a href="https://www.strato.de/faq/article/557/">https://www.strato.de/faq/article/557/</a>
            </p>
            <p>
              Rechtsgrundlage für die Verarbeitung ist Art.6 Abs.1 S.1 f) DS-GVO, mein berechtigtes Interesse an der der Gewährleistung eines störungsfreien Betriebs, der Stabilität meiner  Systeme und der IT-Sicherheit. Eine Zuordnung dieser Daten zu einer bestimmten Person ist nicht möglich.
            </p>
            <p >
              <u>
                5.3 Cookies
              </u></p>
            <p>
              Meine Website verwendet so genannte Cookies. Cookies dienen dazu, mein Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
            </p>
            <p>
              Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
            </p>
            <p>
              Rechtsgrundlage für die Verarbeitung ist Art.6 Abs.1 S.1 f) DS-GVO, mein berechtigtes Interesse an der der Gewährleistung eines störungsfreien und stabilen Betriebs meiner Website.
            </p>
            <p>
              <u>5.4 Datenerhebung per Mail oder Telefon </u>
            </p>
            <p>
              Wenn personenbezogene Daten erhoben werden oder zur Verfügung gestellt werden, erfolgt dies zu den folgenden Zwecken:
            </p>
            <ul>
              <li>Vertragsabwicklung</li>
              <li>Beantwortung Ihrer Fragen</li>
              <li>Kontaktaufnahme mit Ihnen</li>
            </ul>
            <p>
              Ich weise darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. Aus diesem Grund steht es Ihnen frei, personenbezogene Daten auch auf alternativen Wegen, beispielsweise auf dem Postweg oder telefonisch an mich zu übermitteln.
            </p>
            <p>
              Rechtsgrundlage für die Verarbeitung sind Art. 6 Abs.1 S.1 a) DS-GVO – Ihre Einwiligung - und  Art. 6 Abs.1 S.1 b) DS-GVO, die Verarbeitung Ihrer Personenbezogenen Daten zwecks Durchführung  eines Vertrags bzw. Bearbeitung von vorvertraglichen Anfragen.
            </p>
            <p>
              <u>5.5 Soziale Netzwerke und andere Anbieter</u>
            </p>
            <p>
              Mein Online-Angebot beinhaltet auch Inhalte von Drittanbietern. Dabei handelt es sich zum Beispiel um sogenannte "Social Plugins" des Sozialen Netzwerks Instagram. Solche Plugins sind wichtig für die Verbreitung sowie der bestmöglichen Präsentation der Inhalte.
            </p>
            <p>
              Bei der Nutzung meines Online-Angebotes werden auf Servern Ihre IP-Adresse und weitere technisch bedingt anfallende gerätebezogene Informationen wie Bildschirmauflösung, verwendeter Browser und genutztes Betriebssystem registriert. Auch der Anbieter des Plugins bzw. des Codes erhält diese Daten. Aus diesen Daten kann der Anbieter grundsätzlich noch keine Rückschlüsse auf Ihre Person ziehen, aber er weiß beispielsweise., wo Sie sich  (geographisch) aufhalten. Außerdem können die Anbieter auf Ihrem Gerät sogenannte Cookies setzen, mit denen sie Ihr Surfverhalten protokollieren und Ihr beim Surfen verwendetes Gerät wiedererkennen können. Wenn Sie bei einem Sozialen Netzwerk angemeldet sind, während Sie mein Online-Angebot aufrufen, kann der Anbieter zudem Ihr  Surfverhalten Ihrem dort gespeicherten Profil zuordnen. Ich erhalte selbst keine Informationen, wie Sie die Social Plugins nutzen und welche Inhalte Sie teilen oder kommentieren. Der Datenaustausch findet in diesem Fall alleine zwischen Ihnen und den Sozialen Netzwerken bzw. den Anbietern statt.
            </p>
            <p>
              <b>Details zu einzelnen Anbietern:</b>
            </p>
            <p>Unter den folgenden Links können Sie sich über die jeweiligen Datenschutzhinweise der Anbieter informieren, wie diese Ihre Daten erfassen und mit Ihren Daten umgehen.</p>
            <p>
              <u>Instagram</u><br />
              Für die Datenschutzerklärung des Dienstes Instagram klicken Sie bitte
              <a href="https://help.instagram.com/519522125107875">hier</a>.
            </p>
            <p>
              <u>LinkedIn</u><br />
              Für Details zum Umgang von LinkedIn mit Ihren personenbezogenen Daten klicken Sie bitte
              <a href="https://www.linkedin.com/legal/privacy-policy">hier</a>.
            </p>
            <p>
              Zudem verwendet LinkedIn Werbecookies. Wenn Sie die LinkedIn-Werbe-Cookies deaktivieren möchten, dann klicken Sie bitte
              <a href="https://www.linkedin.com/legal/privacy-policy">hier</a>.
            </p>
            <p >
              <u>
                6. Datenschutzerklärung meiner Social-Media-Profile
              </u></p>
            <p >
              <u>
                6.1. Allgemeines - Datenverarbeitung durch Social-Media-Plattformen
              </u></p>
            <p>
              Ich betreibe öffentlich zugängliche Profile in sozialen Netzwerken („Social-Media-Plattform“). Die im Einzelnen von mir genutzten Social-Media-Plattformen finden Sie weiter unten.
            </p>
            <p>
              Social-Media-Plattformen wie z.B. Instagram oder Facebook, etc. können Ihr  Nutzerverhalten in der Regel umfassend analysieren, wenn Sie deren Website oder eine Website mit integrierten Social-Media-Inhalten (z. B. Like-Buttons oder Werbebannern) besuchen. Durch den Besuch meiner Social-Media-Auftritte werden zahlreiche datenschutzrelevante Verarbeitungsvorgänge ausgelöst. Im Einzelnen:
            </p>
            <p>
              Wenn Sie in einem Social-Media-Profil eingeloggt sind und meinen Social-Media-Auftritt besuchen, kann der Betreiber der Social-Media-Plattform diesen Besuch Ihrem Benutzerkonto zuordnen. Ihre personenbezogenen Daten können unter Umständen aber auch dann erfasst werden, wenn Sie nicht eingeloggt sind oder keinen Account bei der jeweiligen Social-Media-Plattform besitzen. Diese Datenerfassung erfolgt in diesem Fall beispielsweise über Cookies, die auf Ihrem Endgerät gespeichert werden oder durch Erfassung Ihrer IP-Adresse.
            </p>
            <p>
              Mit Hilfe der so erfassten Daten können die Betreiber der Social-Media-Plattformen Nutzerprofile erstellen, in denen Ihre Präferenzen und Interessen hinterlegt sind. Auf diese Weise kann Ihnen interessenbezogene Werbung in- und außerhalb der jeweiligen Social-Media-Plattform angezeigt werden. Sofern Sie über einen Account beim jeweiligen sozialen Netzwerk verfügen, kann die interessenbezogene Werbung auf allen Geräten angezeigt werden, auf denen Sie eingeloggt sind oder waren.
            </p>
            <p>
              Bitte beachten Sie außerdem, dass ich nicht alle Verarbeitungsprozesse auf den Social-Media-Plattformen nachvollziehen kann. Je nach Anbieter können daher ggf. weitere Verarbeitungsvorgänge von den Betreibern der Social-Media-Plattformen durchgeführt werden. Details hierzu entnehmen Sie bitte den Nutzungsbedingungen und Datenschutzbestimmungen der jeweiligen Social-Media-Plattformen.
            </p>
            <p >
              <u>
                6.2. Rechtsgrundlage
              </u></p>
            <p>
              Der Betrieb meiner Social Media Profile unter Einbezug der Verarbeitung der personenbezogenen Daten der Nutzer erfolgt auf Grundlage meines berechtigten Interesses an einer zeitgemäßen und unterstützenden Informations- und Interaktionsmöglichkeit für und mit meinen Nutzern und Besuchern gem. Art.6 Abs.1 S.1 f) DS-GVO. Die von den sozialen Netzwerken initiierten Analyseprozesse beruhen ggf. auf abweichenden Rechtsgrundlagen, die von den Betreibern der sozialen Netzwerke anzugeben sind (z. B. Einwilligung im Sinne des Art.6 Abs.1 S.1 a) DS-GVO).
            </p>
            <p >
            <u>
              6.3. Verantwortlicher und Geltendmachung von Rechten
            </u></p>
            <p>
              Wenn Sie eine unserer Social-Media-Profile (z.B. Instagram) besuchen, bin ich gemeinsam mit dem Betreiber der Social-Media-Plattform für die bei diesem Besuch ausgelösten Datenverarbeitungsvorgänge verantwortlich. Sie können Ihre Rechte (Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung, Datenübertragbarkeit und Beschwerde) grundsätzlich sowohl gegenüber mir (siehe dazu auch oben Ziffer 2) als auch gegenüber dem Betreiber der jeweiligen Social-Media-Plattform (z.B. Instagram) geltend machen.
            </p>
            <p>
              Bitte beachten Sie, dass ich trotz der gemeinsamen Verantwortlichkeit mit den Betreibern der Social-Media-Plattformen nicht vollumfänglich Einfluss auf die Datenverarbeitungsvorgänge der Social-Media-Plattform habe. Nur der Betreiber der jeweiligen Social-Media-Plattform hat vollständigen Zugriff auf und Überblick über die Benutzerdaten.
            </p>
            <p >
              <u>
                6.4 Speicherdauer
              </u></p>
            <p>
              Die unmittelbar von mir über den Social-Media-Auftritt erfassten Daten werden aus meinem Systemen gelöscht, sobald der Zweck für ihre Speicherung entfällt, Sie uns zur Löschung auffordern, Sie Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt. Gespeicherte Cookies verbleiben auf Ihrem Endgerät, bis Sie sie löschen. Gesetzliche Bestimmungen/  Aufbewahrungsfristen bleiben unberührt – siehe auch oben Ziffer 3.
            </p>
            <p>
              Auf die Speicherdauer Ihrer Daten, die von den Betreibern der sozialen Netzwerke zu eigenen Zwecken gespeichert werden, habe ich keinen Einfluss. Für Einzelheiten dazu informieren Sie sich bitte direkt bei den Betreibern der sozialen Netzwerke (z. B. in deren Datenschutzerklärung).
            </p>
            <p >
              <u>
                6.5 Social-Media Plattformen im Einzelnen
              </u></p>
            <p>
              Wir nutzen den Dienst Instagram. Anbieter ist Meta Platforms Ireland Ltd. 4 Grand Canal Square Grand Canal Harbour Dublin 2 Ireland.
            </p>
            <p >
              <u>
                Instagram               </u></p>
            <p>
              Sie können Ihre Instagram-Datenschutzeinstellungen selbstständig in Ihrem Nutzer-Account anpassen. Klicken Sie hierzu auf folgenden Link und loggen Sie sich ein:
            </p>
            <p>
              <a href="https://www.instagram.com/accounts/privacy_and_security/">https://www.instagram.com/accounts/privacy_and_security/</a>
            </p>
            <p>
              Details entnehmen Sie der Instagram-Datenschutzerklärung:
            </p>
            <p>
              <a href="https://help.instagram.com/519522125107875">https://help.instagram.com/519522125107875</a>
            </p>
            <p >
              <u>LinkedIn
              </u></p>
            <p>
              Wir verfügen über ein Profil bei LinkedIn. Anbieter ist die LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Irland. LinkedIn verwendet Werbecookies. Diese kannst Du unter
            </p>
            <p>
              <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out">https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a> deaktivieren.
            </p>
            <p>
              Details zur Datenverarbeitung kannst Du der LinkedIn-Datenschutzerklärung entnehmen:
            </p>
            <p>
              <a href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a>
            </p>
            <p >
              <u>
                7. Unerwünschte Werbung
              </u></p>
            <p>
              Der Nutzung der im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Der Betreiber dieser Seite behält sich im Fall der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, ausdrücklich rechtliche Schritte vor.            </p>
            <p >
              <u>
                8. Änderungen der Datenschutzerklärung
              </u></p>
            <p>
              Ich behalte mir vor, die Datenschutzerklärung zu ändern, um sie an geänderte Rechtslagen, oder bei Änderungen des Dienstes sowie der Datenverarbeitung anzupassen. Dies gilt jedoch nur im Hinblick auf Erklärungen zur Datenverarbeitung. Sofern Einwilligungen der Nutzer erforderlich sind oder Bestandteile der Datenschutzerklärung Regelungen des Vertragsverhältnisses mit den Nutzern enthalten, erfolgen die Änderungen nur mit Ihrer Zustimmung.
            </p>
            <p>
              Die Nutzer werden gebeten sich regelmäßig über den Inhalt der Datenschutzerklärung zu informieren.
            </p>
            <p>
              Stand der  Datenschutzerklärung
            </p>
            <p>
              06.02.2023
            </p>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerComponent",
  data: function () {
    return {
      showLegal: false
    }
  },
  methods: {
    openLegal(){
      this.showLegal = !this.showLegal;
      this.$nextTick(() => {
        if(this.showLegal){
          const el = this.$refs.imprint;
          if (el) {
            console.log('scroll!')
            el.scrollIntoView({behavior: 'smooth'});
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .footer{
    background: #ffffff;
    @media screen and (min-width: 700px) {
      min-height: 100vh;
    }
  }
  footer{
    background: #ffffff;
    hr{
      margin: 20px -20px;
      background: #394cf9;
      height: 4px;
      display: block;
    }
  }
  .socials{
    padding-top: 40px;
    text-align: center;
    a{
      display: inline-block;
      margin: 0 15px;
    }
    img{
      width: 40px;
      display: inline-block;
    }
  }
  .router_links{
    float: right;
    span{
      cursor: pointer;
      color: #000000;
      text-decoration: none;
      &:not(:first-child){
        &:before{
          content: ' // ';
        }
      }
    }
  }
  .legal{
    margin-top: 100px;
    width: 100%;
    @media screen and (min-width: 700px) {
      column-width: 50%;
      column-count: 2;
      column-gap: 2rem;
    }
    line-height: 1.5;
    b{
      font-weight: bold;
      color: rgba(0,0,0,1);
    }
    p{
      padding: 10px 0;
      line-height: 1.2;
    }
    &__line {
      border-top: 1px solid #000000;
      padding-top: 50px;
      margin-bottom: 100px;
    }
  }
  .legal__no-column{
    column-width: 100%;
    column-count: 1;
  }
  .legal.legal__line:last-child:after {
    height: 100vh;
    width: 100vw;
    background: #FFF;
    display: block;
    content: '';
  }
</style>
