<template>
  <div class="pic-row" :class="direction" :style="{background: stage.background}" v-if="ready">
    <div class="maxWidth posCenter">
      <div class="innerMaxWidth">
        <div class="pic-row__grid">
            <div class="pic-row__image">
              <div
                  class="pic-row__image__slider"
                  v-if="Object.keys(images.small).length > 0">
                <div :key="image.ID" v-for="(image, index) in images.small"
                     class="pic-row__image__slide"
                >
                  <picture
                      :class="{'pic-row__image__slide--show': index === count}"
                  >
                    <source
                        v-if="typeof images.large[index] !== 'undefined'"
                        :srcset="'https://www.wongebergmann.com/images/image_themes/' + stage.img + '/1000x666/' + images.large[index]"
                        :alt="stage.title"
                        media="(min-width: 500px)"
                        :title="stage.title" />
                    <img
                        :src="'https://www.wongebergmann.com/images/image_themes/' + stage.img + '/500x333/' + image"
                        :alt="stage.title"
                        :title="stage.title" />
                  </picture>
                </div>
              </div>

              <!-- div
                  class="pic-row__image__slider"
                  v-if="Object.keys(images.large).length > 0"
              >
                  <div :key="image.ID" v-for="(image, index) in images.large"
                       class="pic-row__image__slide"
                  >
                    {{index}}
                  {{this.images.small[index]}}
                    <picture
                        :class="{'pic-row__image__slide--show': index === count}"
                    >
                      <source
                          :srcset="'https://www.wingen.dev/wonge/images/large/' + stage.img + '/' + image"
                          :alt="stage.title"
                          media="(min-width: 500px)"
                          :title="stage.title" />
                      <img
                          :src="'https://www.wingen.dev/wonge/images/small/' + stage.img + '/' + image"
                          :alt="stage.title"
                          :title="stage.title" />
                    </picture>
                  </div-->

                <!-- div
                    v-for="index in stage.imgCount" :key="index"
                    :data-index="index"
                    :data-name="stage.img"
                    class="pic-row__image__slide"
                >
                  <picture
                      :class="{'pic-row__image__slide--show': index === count}"
                  >
                    <source
                        :srcset="'https://www.wingen.dev/wonge/images/large/' + stage.img + '/' + index + '.jpg'"
                        :alt="stage.title"
                        media="(min-width: 500px)"
                        :title="stage.title" />
                    <img
                        :src="'https://www.wingen.dev/wonge/images/small/' + stage.img + '/' + index + '.jpg'"
                        :alt="stage.title"
                        :title="stage.title" />
                  </picture>
                </div -->
              <!--/div-->
              <span class="pic-row__pause" v-if="!active" @click="togglePause">Pause</span>
              <span class="pic-row__pause" v-else @click="togglePause">Weiter</span>
            </div>
          <waypoint @change="toggleSlide"></waypoint>
          <div class="pic-row__text">
            <div class="pic-row__position" :class="{'pic-row__black': stage.background === '#FFF'}">
              <h2 v-html="stage.title" />
              <div
                  v-for="text in stage.content.row"
                  :key="text.id"
                  class="pic-row__text-row"
              >
                <span v-html="text" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Waypoint } from "vue-waypoint";

export default defineComponent({
  name: "picture-row",
  components: {
    Waypoint
  },
  data() {
    return {
      pause: false,
      ready: false,
      count: 0,
      autoplayTimeout: 3000,
      autoplay: '',
      startPlay: '',
      active: false,
      images: {
        large: {},
        small: {}
      }
    }
  },props: {
    stage: Object
  },
  created() {
    this.$http.get('https://www.wongebergmann.com/images/read.php?dir=' + this.stage.img)
      .then((result) => {
        if(result.data !== ''){
          if(result.data.small.length > 0){
            this.images.small = result.data.small;
            this.ready = true;
          }
          if(result.data.large.length > 0){
            this.images.large = result.data.large;
          }
        }
      })
  },
  methods: {
    togglePause(){
      this.active = !this.active;
    },
    pictureSlider(){
      if(!this.active){
        if(Object.keys(this.images.small).length - 1 > this.count){
          this.count++;
        }else{
          this.count = 0;
        }
      }
    },
    startInterval(){
      if(!this.active){
        const self = this;
        this.autoplay = setInterval(function(){
          self.pictureSlider();
        }, self.autoplayTimeout);
        this.active = false;
      }
    },
    firstAction(){
      this.pictureSlider();
      this.$nextTick(() => {
        this.startInterval();
      })
    },
    toggleSlide(waypointState){
      if(waypointState.going === 'IN'){
        if(!this.active){
          clearInterval(this.autoplay);
          this.$nextTick(() => {
            this.setTimeout = setTimeout(() => this.firstAction(), this.autoplayTimeout / 2);
          })
        }
      }else{
        clearTimeout(this.setTimeout);
        clearInterval(this.autoplay);
        this.$nextTick(() => {
          this.active = false;
        })
      }
    }
  },
  beforeDestroy () {
    clearTimeout(this.setTimeout);
    clearInterval(this.autoplay)
  },
  computed: {
    direction(){
      if(this.stage.direction === 'left'){
        return 'pic-row--left'
      }
      return 'pic-row--right'
    }
  }
})
</script>

<style lang="scss" scoped>
 .pic-row{
    height: 95vh;
    @media screen and (min-width: 700px) {
      height: 90vh;
    }
    width: 100vw;
    padding: 100px 0;
    position: sticky;
    top: 0;
  }

  .pic-row__grid{
    display: grid;
    gap: 0px 0px;
    @media screen and (max-width: 1800px) {
      padding-left: 20px;
    }

    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
    "image"
    "text";


    @media screen and (min-width: 700px) {
      .pic-row--left & {
        grid-template-rows: 1fr;
        grid-template-columns: .57fr .43fr;
        grid-template-areas:
    "image text";
      }

      .pic-row--right & {
        grid-template-rows: 1fr;
        grid-template-columns: .43fr .57fr;
        grid-template-areas:
    "text image";
      }
    }
  }

  .pic-row__image{
    grid-area: image;
    img{
      max-width: 100%;
    }
  }
  .pic-row__text{
    @media screen and (max-width: 700px) {
      padding-top: 20px;
    }
    grid-area: text;
    position: relative;
    text-align: left;
    .pic-row--left &{
      @media screen and (min-width: 700px) {
        padding-left: 100px;
      }
    }
    h2{
      color: #FFF;
      font-size: 5rem;
      font-weight: bold;
      word-break: break-all;
      margin-bottom: 20px;
    }
    .pic-row__text-row{
      color: #FFF;
      font-size: 1.2rem;
      line-height: 1.5;
      span{
        color: #ffffff;
      }
      &:nth-child(odd){
        padding-left: 60px;
      }
    }
  }
  .pic-row__position{
    position: relative;
    top: 100%;
    transform: translateY(-100%);
    div{
      color: #ffffff;
    }
  }
  .pic-row__image__slider{
    position: relative;
    .pic-row__image__slide{
      picture{
        display: none;
        &.pic-row__image__slide--show{
          display: block;
        }
      }
    }
  }
  .pic-row__pause{
    cursor: pointer;
    padding-top: 10px;
    display: inline-block;
  }
  .pic-row__black{
    *{
      color: #000 !important;
    }
    color: #000 !important;
  }
</style>
