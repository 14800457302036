<template>
  <div class="home">
    <div
        @click="scrollTo('contact')"
        class="telephone">
      <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           viewBox="0 0 80 80" style="enable-background:new 0 0 80 80;" xml:space="preserve">
        <g>
          <defs>
            <rect id="SVGID_1_" y="0" width="80" height="80"/>
          </defs>
          <clipPath id="SVGID_2_">
            <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
          </clipPath>
          <g class="t0">
            <path class="t1" d="M40,80c22.1,0,40-17.9,40-40S62.1,0,40,0S0,17.9,0,40S17.9,80,40,80"/>
            <path class="t2" d="M23,12c0,0,3.4-2.9,5.6-1.1s4.7,4.5,7.6,12.6c1.1,2.7-0.7,3.8-2,4.5c-1.3,0.7-6.3,2.7-7.9,2.9
            c0.7,4,4.5,12.8,8.1,16.9c3.6,4,9.2,8.8,12.1,10.1c0.9-4.5,5-7.9,5.8-8.5s1.6-1.6,4,0.5c2.5,2,8.1,7,9,11c0.4,2.7,0.7,1.8-0.9,3.4
            s-6.1,6.3-8.8,6.5c-2.7,0.7-4.5,0.2-6.8-0.9c-2.2-1.1-16-8.1-24.3-20.5c-6.8-9.4-9.7-23.4-9.9-27.2S22.9,12,23,12"/>
          </g>
        </g>
      </svg>
    </div>
    <opener />
    <div class="behaviour" ref="newspaper">
      <picture-row :stage="stage[0]"/>
    </div>
    <div class="behaviour" ref="portrait">
      <picture-row :stage="stage[1]"/>
    </div>
    <div class="behaviour" ref="kultur">
      <picture-row :stage="stage[2]"/>
    </div>
    <div class="behaviour" ref="puplications">
      <picture-row :stage="stage[4]"/>
    </div>
    <div class="behaviour" ref="food">
      <picture-row :stage="stage[5]"/>
    </div>
    <div class="behaviour" ref="editorial">
      <picture-row :stage="stage[3]"/>
    </div>
    <div class="behaviour" ref="wonge">
      <picture-row :stage="stage[6]"/>
    </div>
    <!--div class="behaviour">
      <picture-row :stage="stage[6]"/>
    </div-->
    <div class="behaviour" ref="contact">
      <wonge />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PictureRow from "@/components/picture-row";
import Opener from "@/components/opener";
import Wonge from "@/components/wonge";
import {EventBus} from "@/main";

export default {
  name: 'Home',
  components: {
    Wonge,
    Opener,
    PictureRow
  },
  data: function () {
    return {
      stage: {
        0: {
          pos: 0,
          background: '#0042a4',
          title: 'Newspaper',
          direction: 'left',
          img: 'newspaper',
          content: {
            row: [
              'Bilder für die Zeitung'
            ]
          }
        },
        1: {
          pos: 1,
          background: '#ba000c',
          title: 'Portrait',
          direction: 'right',
          img: 'portrait',
          content: {
            row: [
              ''
            ]
          }
        },
        2: {
          pos: 2,
          background: '#1e2f45',
          title: 'Kultur',
          direction: 'left',
          img: 'kultur',
          content: {
            row: [
              ''
            ]
          }
        },
        3: {
          pos: 3,
          background: '#dc5b48',
          title: 'Editorial',
          direction: 'right',
          img: 'editorial',
          content: {
            row: [
              'Bilder vom Bauen'
            ]
          }
        },
        4: {
          pos: 4,
          background: '#FFF',
          title: 'Publications',
          direction: 'left',
          img: 'puplications',
          content: {
            row: [
              ''
            ]
          }
        },
        5: {
          pos: 5,
          background: '#938f8f',
          title: 'Food',
          direction: 'left',
          img: 'food',
          content: {
            row: [
              ''
            ]
          }
        },
        6: {
          pos: 6,
          background: '#3e54ff',
          title: 'Wonge',
          direction: 'right',
          img: 'wonge',
          content: {
            row: [
              'Augen Blicke'
            ]
          }
        }
      }
    }
  },
  created() {
    EventBus.$on('scrollTo', (ref) => {
      this.scrollTo(ref);
    })
  },
  methods: {
    scrollTo(ref){
      const el = this.$refs[ref];
      if (el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    }
  }
}
</script>
<style lang="scss">
.behaviour{
  @media screen and (min-width: 700px) {
    position: sticky;
    top:0;
  }
}
.telephone{
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9;
  width: 50px;
  height: 50px;
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 30%);
  border-radius: 100%;
  @media screen and (min-width: 700px) {
    display: none;
  }
}
.t0{clip-path:url(#SVGID_2_);}
.t1{fill:#FFFFFF;}
.t2{fill:#3E54FF;}
</style>
