<template>
  <div class="opener">
    <nav @click="scrollTo('contact')">
      <img src="../assets/kontakt.svg" />
    </nav>
    <div class="opener_image">
      <div class="icon">
        <img src="../assets/wonge_loge.svg" />
      </div>
    </div>
    <div class="opener_content maxWidth">
      <div class="innerMaxWidth">
        <h1 class="opener_headline">
          &raquo;Wenn es auch nur eine hundertstel Sekunde eines Lebens ist, sie sollte eine Geschichte erzählen.&laquo;
        </h1>
        <!--div class="opener_text">
        </div-->
        <ul class="opener_nav">
          <li class="opener_nav_item"
              data-title="Newspaper"
              @click="scrollTo('newspaper')">NEWSPAPER</li>
          <li class="opener_nav_item"
              data-title="Portrait"
              @click="scrollTo('portrait')">PORTRAIT</li>
          <li class="opener_nav_item__break"></li>
          <li
              class="opener_nav_item"
              data-title="Kultur"
              @click="scrollTo('kultur')">KULTUR</li>
          <li
              class="opener_nav_item"
              data-title="Publications"
              @click="scrollTo('puplications')">Publications</li>
          <li class="opener_nav_item"
              data-title="Food"
              @click="scrollTo('editorial')">Editorial</li>
          <li class="opener_nav_item"
              data-title="Food"
              @click="scrollTo('food')">FOOD</li>
          <li class="opener_nav_item__break"></li>
          <!--li class="opener_nav_item"
              data-title="Editorial"
              @click="scrollTo('editorial')">EDITORIAL</li-->
          <li class="opener_nav_item"
              data-title="Wonge"
              @click="scrollTo('wonge')">WONGE</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/main";
export default {
  name: "opener",
  methods: {
    scrollTo(ref){
      EventBus.$emit('scrollTo', ref);
    }
  }
}
</script>

<style lang="scss" scoped>
  .icon{
    position: absolute;
    z-index: 1;
    width: 300px;
    left: 50%;
    top: 35px;
    transform: translateX(-50%);
    @media screen and (max-width: 700px) {
      bottom: 10vh;
      top: unset;
    }
  }
    .op0{enable-background:new}.op1{fill:#fff}.op3{fill:#3e54ff}

  .opener_image{
    display: block;
    background-image: url('https://www.wongebergmann.com/images/opener.jpg');
    background-size: cover;
    background-position: top center;
    position: relative;
    max-width: 1800px;
    margin: 0 auto;
    height: 100vh;
    @media screen and (min-width: 700px) {
      height: 95vh;
    }
    &:after{
      background: rgb(255,255,255);
      background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(107,107,107,1) 100%);
      content: '';
      width: 100%;
      height: 30vh;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
    picture{
      img{
        max-width: 100%;
        max-height: 90vh;
      }
    }
  }
  .opener_headline{
    text-align: center;
    font-size: 5rem;
    line-height: 1;
    width: 80%;
    margin: 0 auto 20px auto;
    text-transform: uppercase;
  }
  .opener_content{
    background: #ffffff;
    padding: 40px 0;
    position: relative;
    z-index: 2;
    @media screen and (min-width: 700px) {
      margin-top: -5vh;
    }
    @media screen and (min-width: 700px)  and (max-width: 1800px) {
      padding: 40px;
    }
  }
  .opener_text{
    display: block;
    width: 60vw;
    text-align: left;
    line-height: 1.3;
    margin-bottom: 20px;
  }
  .opener_nav{
    display: block;
    list-style: none;
    text-align: center;
    margin: 20px 0;
    @media screen and (min-width: 700px) {
      width: 80%;
      max-width: 730px;
      margin: 100px auto 20px auto;
    }
  }
  @media screen and (min-width: 1800px) {
    li.opener_nav_item:nth-child(4) {
      //margin-left: -100px;
    }
  }
  .opener_nav_item{
    display: inline-block;
    font-size: 2.75rem;
    font-weight: bold;
    padding-right: 10px;
    line-height: 1.1;
    cursor: pointer;
    position: relative;
    text-transform: uppercase;
    overflow: hidden;
    &:hover{
      &:first-child{
        &:after{
          width: 100%;
          left: 0;
        }
      }
      &:after{
        width: calc(100% - 10px);
      }
    }
    &:first-child {
      &:after{
        left: 0;
      }
    }
    &:after{
      display: none;
      transition: width ease-in 0.6s;
      background: #394cf9;
      color: #FFF;
      content: attr(data-title);
      width: calc(100% - 30px);
      //display: block;
      position: absolute;
      top: 0.3rem;
      left: 29px;
      line-height: 1;
      font-size: 2.75rem;
      font-weight: bold;
      text-transform: uppercase;
      overflow: hidden;
      opacity: 0.3;
      padding: 0;
    }
    &:hover{
      &:after{

      }
    }
    &:not(:first-child){
      &:before{
        content: '//';
        color: #394cf9;
        position: relative;
        padding-right: 10px;
      }
    }
    @media screen and (min-width: 700px) {
      &:nth-child(5) {
        &:before {
          //content: '';
        }
      }
    }
    &:last-child{
      color: #394cf9;
    }
  }
  nav{
    position: fixed;
    @media screen and (max-width: 700px) {
      display: none;
    }
    img{
      width: 100px;
    }
    z-index: 100;
    max-width: 1800px;
    font-size: 1.5rem;
    padding: 40px;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
    width: 100%;
    cursor: pointer;
    color: #394cf9;
    left: 50%;
    transform: translateX(-50%);
  }
  .opener_nav_item__break {
    display: inline-block;
  }
  @media screen and (min-width: 1800px) {
  }
</style>
