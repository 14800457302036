<template>
  <div>
    <div class="wonge">
      <div class="maxWidth">
        <div class="innerMaxWidth">
          <div class="wonge__grid">
            <div class="wonge__image">
              <img src="https://www.wongebergmann.com/images/wonge_bergmann.jpg" />
            </div>
            <div class="wonge__placeholder1"></div>
            <div class="wonge__placeholder2"></div>
            <div class="wonge__content">
              <h3>
                Wonge Bergmann
              </h3>
              <div class="flow-text">
                Bekennender Hamburger.
                <p>
                  Dort geboren und Hochschulstudium mit Abschluss als Kommunikationsdesigner mit Schwerpunkt Fotografie.
                  Aus Frankfurt am Main als selbstständiger Fotograf tätig im Bereich <span>Redaktionsfotografie</span> für Frankfurter Allgemeine Zeitung, Fotografie für <span>Geschäftsberichte (inklusive Vorstandsporträts), Porträtshootings</span> im Rahmen von <span>Employer Branding Kampagnen, Eventfotografie, Foodfotografie, Fotografie
                für Corporate Magazines/ Hauszeitschriften</span> von Unternehmen. <span>Businessporträts für Homepages, Konzert- und Theaterfotografie.</span> Lebt allein unter Frauen (Töchter und Ehefrau) in Frankfurt am Main.
                </p>
              </div>
              <div class="wonge__contact">
                <a href="tel:+491716206633"><span>Mobil</span> +49 (0) 171 620 66 33</a>
                <a href="mailto:wonge@wongebergmann.com"><span>Mail an Wonge</span></a>
              </div>
            </div>
          </div>
          <ul class="wonge__references">
            <li>Seit vielen Jahren treu verbunden</li>
            <li>FRANKFURTER ALLGEMEINE ZEITUNG</li>
            <li>FRANKFURTER ALLGEMEINE SONNTAGSZEITUNG</li>
            <li>DZ BANK AG</li>
            <li>DZ PRIVATBANK S.A.</li>
            <li>KION GROUP AG</li>
            <li>ASSET - AGENTUR FÜR FINANZKOMMUNIKATION GMBH</li>
            <li>K&K CAPITAL GMBH</li>
            <li>BRITA GMBH</li>
            <li>HERAEUS HOLDING</li>
            <li>4E GMBH</li>
            <li>NILS HENKEL</li>
            <li>HISTORISCHE GESELLSCHAFT DEUTSCHE BANK E.V.</li>
            <li>MÄCKLER ARCHITEKTEN GMBH</li>
            <li>DEHOGA HESSEN</li>
            <li>DEMATIC GMBH</li>
            <li>DAIDALOS GMBH</li>
            <li>AMERICAN CENTURY INVESTMENTS (ACI) EU GMBH</li>
            <li>ENSEMBLE MODERN</li>
            <li>INTERNATIONALE ENSEMBLE MODERN AKADEMIE</li>
            <li>ALTE OPER FRANKFURT</li>
            <li>INTERNATIONALES PIANOFORUM</li>
            <li>INTERNATIONALE ENSEMBLE MODERN AKADEMIE</li>
            <li>JRE - JEUNES RESTAURATEURS</li>
            <li>LSG GROUP</li>
            <li>QUATTRON MANAGEMENT CONSULTING GMBH</li>
            <li>KÜNSTLERHAUS MOUSONTURM FRANKFURT AM MAIN</li>
            <li>SCHAUSPIEL FRANKFURT</li>
            <li>PERSPEKTIVEN DER WIRTSCHAFTSPOLITIK</li>
            <li>INTERNATIONAL CAPITAL MARKET ASSOCIATION (ICMA)</li>
            <li>THE COVERED BOND REPORT (TCBR)</li>
            <li>BUNDESVERBAND DER DEUTSCHEN VOLKSBANKEN UND RAIFFEISENBANKEN – BVR</li>
            <li>BÖRSENZEITUNG</li>
            <li>PERSPEKTIVEN DER WIRTSCHAFTSPOLITIK</li>
            <li>VERLAG L‘ARCHE EDITEUR</li>
            <li>TRETORRI VERLAG GMBH</li>
            <li>HEEL VERLAG</li>
            <li>HAMPP VERLAG</li>
            <li>DZ HYP AG</li>
            <li>ROWOHLT VERLAG GMBH</li>
            <li>PIPER VERLAG GMBH</li>
            <li>EDELMAN GMBH</li>
            <li>BÜRGERINSTITUT</li>
            <li>POLYTECHNISCHE GESELLSCHAFT</li>
            <li>VERLAG KIEPENHEUER & WITSCH GMBH & CO. KG</li>
          </ul>
          <footer-component />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FooterComponent from "@/components/footer";
export default {
  name: "wonge",
  components: {FooterComponent}
}
</script>

<style lang="scss" scoped>
  .wonge{
    position: relative;
    @media screen and (max-width: 700px) {
      padding-bottom: 100px;
    }
    @media screen and (min-width: 700px) {
      min-height: 100vh;
    }
    background: #FFF;
  }
  .wonge__grid{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
    "image"
    "text";
    padding: 40px 0;
    @media screen and (min-width: 700px) {
      grid-template-columns: 0.6fr 1.4fr;
      grid-template-rows: auto 1fr;
      grid-template-areas:
       "image placeholder1"
      "placeholder2 text";
    }
    gap: 0px 0px;
  }
  .wonge__content{
    grid-area: content;
    padding: 0 20px;
  }
  .wonge__placeholder1{
    grid-area: placeholder1;
    @media screen and (max-width: 700px) {
      display: none;
    }
  }
  .placeholder2{
    grid-area: placeholder2;
    @media screen and (max-width: 700px) {
      display: none;
    }
  }
  .wonge__image{
    grid-area: image;
    img{
      width: 400px;
      @media screen and (max-width: 700px) {
        max-width: 90%;
      }
    }
  }
  h3{
    font-size: 3.125rem;
    color: #394cf9;
    text-align: left;
    @media screen and (min-width: 700px) {
      text-align: right;
    }
    margin-bottom: 20px;
    width: 80%;
  }
  .wonge__contact,
  wonge__contact *{
    font-size: 1.75rem;
    span{
      color: #394cf9;
    }
    text-align: left;
    h4{
      margin-bottom: 20px;
    }
    a{
      color: #000;
      display: block;
      text-decoration: none;
      line-height: 2;
    }
    a[href^="mailto"]{
      text-transform: uppercase;
    }
  }
  .wonge__contact{
    padding-top: 20px;
  }
  .flow-text{
    text-align: left;
    font-size: 1.25rem;
    line-height: 1.5;
    overflow: hidden;
    @media screen and (min-width: 700px) {
      width: 80%;
    }
    span{
      color: #394cf9;
    }
  }
  .wonge__references{
    list-style: none;
    margin-top: 100px;
    li{
      display: inline;
      font-size: 1.25rem;
      line-height: 1.5;
      &:first-child{
        color: #394cf9;
        font-weight: bold;
        padding-right: 20px;
      }
      &:not(:first-child){
        &:before{
          padding-left: 2px;
          content: ' / ';
          color: #394cf9;
        }
      }
      &:nth-child(2){
        &:before{
          content: '' !important;
        }
      }
    }
  }
</style>
